<template>
    <v-container fluid >
        <v-layout  justify-center align-center>
            <v-flex xs12 sm8 >
                <v-card
                elevation="2"
                class="mt-10"
                >
                    <v-card-title class="px-6 card-header white--text">
                        <v-icon class="mr-3 white--text">mdi-account-circle</v-icon> <h3>User Profile</h3>
                    </v-card-title>

                    <v-tabs
                    v-model="tabs"
                    >
                        <v-tab v-for="item in tabsMinMax" :key="item.tab" class="text--text">
                            <v-icon class="mr-2 text--text">{{ item.icon }}</v-icon>
                            {{ item.tabName }}
                        </v-tab>
                    </v-tabs>

                    <v-divider class="pb-4"></v-divider>

                    <!-- Container Tabs -->
                    <v-tabs-items v-model="tabs">

                        <!-- Tab Edit Profile-->
                        <v-tab-item class="container-tab-modul">

                            <v-form>

                                <v-container>

                                    <v-row>

                                        <v-col
                                        cols="12"
                                        sm="6"
                                        >
                                        <v-text-field
                                            label="First Name"
                                            outlined
                                            v-model="objDataPost.firstname"
                                            dense
                                        ></v-text-field>
                                        </v-col>

                                        <v-col
                                        cols="12"
                                        sm="6"
                                        >
                                        <v-text-field
                                            label="Last Name"
                                            outlined
                                            v-model="objDataPost.lastname"
                                            dense
                                        ></v-text-field>
                                        <!-- prepend-inner-icon="mdi-account" -->
                                        </v-col>

                                        <v-col
                                        cols="12"
                                        sm="3"
                                        >
                                        <v-text-field
                                            label="User Name"
                                            outlined
                                            prepend-inner-icon="mdi-account"
                                            v-model="objDataPost.name"
                                            dense
                                        ></v-text-field>
                                        </v-col>

                                        <v-col
                                        cols="12"
                                        sm="3"
                                        >
                                        <v-text-field
                                            label="Email"
                                            outlined
                                            prepend-inner-icon="mdi-email"
                                            type="email"
                                            v-model="objDataPost.email"
                                            readonly
                                            hint="Email cannot be edited. Please inform to system admin to create new account."
                                            dense
                                        ></v-text-field>
                                        </v-col>

                                        <v-col
                                        cols="12"
                                        sm="3"
                                        >
                                        <v-text-field
                                            label="Mobile Phone"
                                            outlined
                                            prepend-inner-icon="mdi-cellphone-sound"
                                            @keypress="isNumber($event)"
                                            v-model="objDataPost.phoneNo"
                                            dense
                                        ></v-text-field>
                                        </v-col>

                                        <v-col
                                        cols="12"
                                        sm="3"
                                        >
                                        <v-text-field
                                            label="Designation"
                                            outlined
                                            prepend-inner-icon="mdi-tie"
                                            v-model="objDataPost.designation"
                                            dense
                                        ></v-text-field>
                                        </v-col>
                                        
                                    </v-row>

                                    <v-divider class="pb-2"></v-divider>

                                    <v-row class="justify-center align-center">
                                        <v-card-actions>
                                            <v-btn class="white--text primary"  @click="updateProfile()">
                                                UPDATE
                                            </v-btn>
                                        </v-card-actions>
                                    </v-row>

                                </v-container>

                            </v-form>

                        </v-tab-item>

                        <!-- Tab Change Password-->
                        <v-tab-item class="container-tab-modul">

                            <v-container>
                                <v-form>
                                      <v-row class="justify-center align center">
                                        <v-col cols="12" sm="12">
                                            <v-text-field
                                                label="Current Password"
                                                outlined
                                                type="password"
                                                v-model="objDataPwdPost.currentPassword"
                                                :append-icon="valueCurP ? 'mdi-eye' : 'mdi-eye-off'"
                                                dense
                                            ></v-text-field>
                                        </v-col>

                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                label="New Password"
                                                outlined
                                                @input="newPassword"
                                                :append-icon="valueNewP ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="() => (valueNewP = !valueNewP)"
                                                :type="valueNewP ? 'password' : 'text'"
                                                dense
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                class="error-pwd"
                                                :error="errorNotSame"
                                                :hint="errorHint"
                                                label="Re-enter New Password"
                                                outlined
                                                @input="checkReenterPassword"
                                                :append-icon="valueReNewP ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="() => (valueReNewP = !valueReNewP)"
                                                :type="valueReNewP ? 'password' : 'text'"
                                                v-model="objDataPwdPost.password"
                                                dense
                                            ></v-text-field>
                                        </v-col>

                                    </v-row>

                                    <v-divider class="pb-4"></v-divider>

                                    <v-row class="justify-center align">
                                        <v-btn class="white--text button mr-3" :disabled="objDataPwdPost.currentPassword == '' || samaKeTak == false" @click="udpatePassword">
                                            SAVE
                                        </v-btn>
                                    </v-row>
                                </v-form>
                            </v-container>

                        </v-tab-item>

                    </v-tabs-items>
                
                </v-card>
            </v-flex>
        </v-layout>

        <!-- Popups/Dialogs Success -->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccessUpdateProfile"
            width="500"
            >
            <v-card>
                <!--<v-card-title class="text-h5 success white--text">
                    Success!
                <v-icon v-if="whatToUpdate != 'password'" style="color:white;margin-left:15px;">mdi-account-check</v-icon>
                <v-icon v-if="whatToUpdate == 'password'" style="color:white;margin-left:15px;">mdi-lock-check-outline</v-icon>
                </v-card-title>-->

                <v-card-title class="text-h5 white--text button">
                    <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Form has been submitted
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Your {{whatToUpdate}} has successfully updated. {{addTextUpdatePwd}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="whatToUpdate != 'password'"
                    text
                    @click="dialogSuccessUpdateProfile = false;loadDataUsers()"
                >
                    Close
                </v-btn>
                <v-btn
                    v-if="whatToUpdate == 'password'"
                    text
                    @click="goToPage('/login')"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Error -->
        <div class="text-center">
            <v-dialog
            v-model="dialogErrorUpdateProfile"
            width="500"
            >
            <v-card>
                <!--<v-card-title class="text-h5 error white--text">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
                </v-card-title>-->

                <v-card-title class="text-h5 white--text error">
                    <v-icon class="white--text mr-2">mdi-text-box-remove</v-icon>
                    Form failed to submit
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                    {{errorCurrentPwd}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialogErrorUpdateProfile = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>



    </v-container>
</template>

<script>
import axios from "axios";

export default {

    data: () => ({

        whatToUpdate: "",

        dialogSuccessUpdateProfile: false,
        dialogErrorUpdateProfile: false,

        tabs: null,
        tabsMinMax: [
            {tabName: "Edit Profile", icon: "mdi-account-edit"},
            {tabName: "Change Password", icon: "mdi-form-textbox-password"},
        ],

        itemsDesignation: ["Administration","Operational","General"],

        valueNewP: String,
        valueReNewP: String,
        valueCurP: String,
        

        allDataUsers: [],
        myProfileData: [],

        objDataPost: {
            IdUser: "",
            idUserAccess: "",
            dataValidationStatus: "",
            name: "",
            phoneNo: "",
            firstname: "",
            lastname: "",
            role: "",
            designation: "",
            alert1: "",
            email: "",
        },

        newPwdStr: '',
        samaKeTak: false,

        objDataPwdPost: {
            firstname: "",
            lastname: "",
            name: "",
            email: "",
            phoneNo: "",
            designation: "",
            idUser: "",
            dataValidationStatus: "",
            role: "",
            alert1: "",
            currentPassword: "",
            password: ""
        },

        errorNotSame: false,
        errorHint: '',

        addTextUpdatePwd: "",
        errorCurrentPwd: "",

    }),
    methods: {

        // SECTION EDIT PROFILE
        loadDataUsers(){

            this.myProfileData = [];

            axios.get( this.globalUrl + 'mqm2/users/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                let data = response.data;
                this.allDataUsers = response.data;

                for(let i in data){
                    if (data[i].iduser == this.$store.getters.user.idUser) {
                        this.myProfileData.push(data[i]);

                        // My Profile
                        this.objDataPost.IdUser = data[i].iduser
                        this.objDataPost.idUserAccess = data[i].idUserAccess
                        this.objDataPost.dataValidationStatus = data[i].dataValidationStatus
                        this.objDataPost.name = data[i].name
                        this.objDataPost.phoneNo = data[i].phoneNo
                        this.objDataPost.firstname = data[i].firstname
                        this.objDataPost.lastname = data[i].lastname
                        this.objDataPost.role = data[i].role
                        this.objDataPost.designation = data[i].designation
                        this.objDataPost.alert1 = data[i].alert1
                        this.objDataPost.email = data[i].email

                        // Change Password
                        this.objDataPwdPost.firstname = data[i].firstname
                        this.objDataPwdPost.lastname = data[i].lastname
                        this.objDataPwdPost.name = data[i].name
                        this.objDataPwdPost.email = data[i].email
                        this.objDataPwdPost.phoneNo = data[i].phoneNo
                        this.objDataPwdPost.designation = data[i].designation
                        this.objDataPwdPost.idUser = data[i].idUser
                        this.objDataPwdPost.role = data[i].role
                        this.objDataPwdPost.alert1 = data[i].alert1
                        // this.objDataPwdPost.password = data[i].alert1

                    }
                }

                
            })
            .catch(error => {
                console.log(error);
            })
        },

        updateProfile(){
            console.log(this.objDataPost);

            let jsonDataProfile = JSON.stringify(this.objDataPost);
            console.log(this.objDataPost);
            console.log(jsonDataProfile);

            delete this.objDataPost["email"]

            axios.post(this.globalUrl + 'mqm2/users/edit', this.objDataPost, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
                this.dialogSuccessUpdateProfile = true;
                this.whatToUpdate = "data profile"

                this.loadDataUsers()
            })
            .catch(error => {
                console.log(error);
                this.dialogSuccessUpdateProfile = false;
                this.dialogErrorUpdateProfile = true;
                this.errorCurrentPwd = "Please try again."
            })

        },


        // SECTION CHANGE PASSWORD
        newPassword(text){
            this.newPwdStr = text;

            if (text != this.objDataPwdPost.password) {
                this.errorNotSame = true;
                this.errorHint = "The password is not matching with current password"
                this.samaKeTak = false;
            }
            else{
                this.errorNotSame = false;
                this.errorHint = "";
                this.samaKeTak = true;
            }
        },

        checkReenterPassword(text){
            // console.log(text);

            if (text != this.newPwdStr) {
                this.errorNotSame = true;
                this.errorHint = "The password is not matching with current password"
                this.samaKeTak = false;
            }
            else{
                this.errorNotSame = false;
                this.errorHint = "";
                this.samaKeTak = true;
            }
        },

        udpatePassword(){

            if (this.samaKeTak == true) {
                console.log(this.objDataPwdPost);

                let jsonDataPassword = JSON.stringify(this.objDataPwdPost);
                console.log(jsonDataPassword);

                axios.post(this.globalUrl +'mqm2/users/edit', jsonDataPassword, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.user.token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) => {
                    console.log(response);
                    this.dialogSuccessUpdateProfile = true;
                    this.whatToUpdate = "password"
                    this.addTextUpdatePwd = "The system will be auto logout after this, and please login back to enter the system."
                })
                .catch(error => {
                    console.log(error.response);
                    this.dialogSuccessUpdateProfile = false;
                    this.dialogErrorUpdateProfile = true;
                    
                    // this.errorCurrentPwd = "Please make sure current password is matching with your login password."

                    if(error.response.status == 500){
                        this.errorCurrentPwd = "Internal Server Error"
                    }
                    else{
                        if(error.response.data.message != undefined){
                            if(error.response.data.message == ''){
                                this.errorCurrentPwd = "Error. Something went wrong. Please try again later."
                            }
                            else{
                                this.errorCurrentPwd = error.response.data.message
                            }
                        }
                        else{
                            this.errorCurrentPwd = "Error. Something went wrong. Please try again later."
                        }
                    }
                    
                })
            }
            

        },

    },
    mounted(){
        this.loadDataUsers();
        // console.log(this.myProfileData);
        // console.log(this.$store.getters.user);
    },
}
</script>

<style>

.error-pwd > div > div:nth-child(2) > .v-messages__message,
.error-pwd > div > div:nth-child(2) > .v-messages{
    color: red !important;
}

</style>